.CardBG {
    background-image: url('../../../Images/fundoroxo.jpg');
    background-size: cover;
    background-color: black;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 0.2s;
    color: white;
        height: 100%;
        cursor: default;
}
.mes {
    color: lightgray;
}
.cardbody{
    display: flex;
flex-direction: column;
justify-content:space-around;
}
  
.CardBG:hover {
transform: scale(1.05);
}

.precos{
    color:white;
    font-family: "Press Start 2P", system-ui;
        background-color: #530031;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
}
