/* Fonts */
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
@font-face {
  font-family: 'Fibra';
  src: local('Fibra'), url(./Font/fibra/Fibra-Light.otf) format(OpenType);
}

/* Global Styles */
body {
  margin: 0;
  font-family: "Press Start 2P", system-ui;
    font-size: 1rem !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
}
.fundo {
  background-attachment: fixed;
  background-size: cover;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.backFormupin {
  width: calc(100vw - 40%);
  height: 100dvh;
}

.logologinup {
  position: absolute;
  /* filter: invert(); */
  width: 12%;
}

.botaoCriar {
  background-color: purple !important;
  border-color: purple !important;
}

.botaoCriar:disabled {
  pointer-events: all !important;
  cursor: not-allowed;
  background-color: grey !important;
  border-color: grey !important;
}

.fundoImgSingUp {
  background-image: url(https://static1.minhavida.com.br/diseases/12/8a/ab/d2/mulher-mexendo-no-celular-orig-1.jpg);
  overflow: hidden;
  height: 100vh;
  width: calc(100vw - 60%);
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  background-color: lavender;
}

.contlogin {
  width: fit-content !important;
  background-color: #0c0c0c;
  background-size: cover;
  background-blend-mode: overlay;
  color: white;
  border: 0.2rem solid #fff;
  border-radius: 2rem;
  padding: 0.4rem;
  box-shadow: 0 0 .2rem #fff, 0 0 .2rem #fff, 0 0 2rem #bc13fe, 0 0 0.8rem #bc13fe, 0 0 2.8rem #bc13fe, inset 0 0 1.3rem #bc13fe;
}

.logoimg {
  width: 100%;
}

.rowint {
  width: 30vw;
}

.viewpass {
  float: right;
  margin-left: -25px;
  margin-top: -26px;
  position: relative;
  font-size: large;
  z-index: 2;
}

/* Dashboard */
.dashboardFundo {
  max-width: 100dvw !important;
  padding-top: 1rem;
  margin: 0;
    min-height: 100dvh;
}

.dashboardprincipal {
  background-color: transparent;
}


.dashboardtituloLinks {
  text-align: center;
}

.dashboardFundoLink {
  height: 8rem;
    border-radius: 1.6rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.linksList {
  width: 100%;
}

/* Progress Bar */
.barwarper {
  width: 100%;
}

.bar {
  border: ridge medium black;
  height: 100%;
  width: 80%;
}

/* Molduras */
.frameOption {
  width: 5%;
  cursor: pointer;
}

.dashboardMolduraPerfil {
  position: absolute;
  width: 200px; /* Ajuste conforme necessário */
  height: 200px; /* Ajuste conforme necessário */
  z-index: 2;
}
.configLinksIcons i, .addLink{
  cursor: pointer;
  font-size: 2rem;
}

.arrastarLink {
  font-size: xx-large;
}

/*Perfil*/
.perfilsuperiorwarp {
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10rem;
  height: 10rem;
}
.dashboardImagePerfil {
  filter: opacity();
  position: relative;
  z-index: 1;
  width: 150px;
  height: 150px;
  object-fit: cover;
  transition: filter 0.3s;
}

.iconContainer {
  position: absolute;
  top: 33%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  gap: 1rem;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 4;
}
  
.iconContainer {
  opacity: 1;
}

.frameepefilwarp:hover .dashboardImagePerfil {
  filter: brightness(70%);
}

.EditIcon,
.DeleteIcon {
  /* color: rgb(219, 219, 219); */
  color: 0;
  font-size: 1.5rem;
  cursor: pointer;
    z-index: 3;
}

.EditIcon:hover,
.DeleteIcon:hover {
  color: white;
}

.previewFundo {
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  position: -webkit-sticky; /* para compatibilidade com navegadores antigos */
  position: sticky;
  z-index: 10;
    height: 100dvh;
  }

.previewFrame {
  /* margin: auto; */
  margin-left: auto;
  margin-right: auto;
  padding: auto;
  border: solid .5em black;
  border-radius: 2rem;
  overflow: hidden;
  position: -webkit-sticky; /* para compatibilidade com navegadores antigos */
  position: sticky;
  top: 0;
  z-index: 11;
}

.linkENome{
  width: 80%;
  overflow: hidden;
}

.linkUrl{

  overflow-x: scroll;
}

.linkUrl::-webkit-scrollbar {
  display: none;
}

.arrastarWarp{
  width: 10%;
}

.loadingFundo {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.username{
  width:fit-content;
}

.usernamePefilWarp {
  margin-top: 2rem;
    width: fit-content;
  padding: 2%;
  padding-left: 8%;
  padding-right: 8%;
  border-radius: 1.6rem;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 15px;
}
  
.usernamePaginaWarp {
    padding: 2%;
    padding-left: 5%;
    padding-right: 5%;
    border-radius: 1.6rem;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 15px;
  min-width: max-content;
}

.paginaFundoWarp{

  overflow-x: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: beige;
  background-image: url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLpJE2Gy-GdEp7koniPZ5sXuH4E7XiLTYOPAUBO57cVsDOhETa3D6dPo_dKPqtEl3PMUg);
    background-size: 2%;
    background-blend-mode: soft-light;

}
.paginaFundo {
  min-height: 100vh;
    width: 30rem;
  background-position-x: 65%;
  background-size: cover;
  background-color: beige;
  display: flex;
  flex-direction: column;

}

.profileFundo {
  transition: max-height 0.3s ease;
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  position: -webkit-sticky;
  /* para compatibilidade com navegadores antigos */
  position: sticky;
  flex-direction: column;
  z-index: 10;
  top: 0;
  height: 100dvh !important;
}



.paginaLinksList{
  width: 100vw;
  max-width: 20rem;
}

.fecharBtn {
  cursor: pointer;
}

.paginaLink {
  width: 95%;
  height: 4rem;
  text-decoration: none;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.paginaLink:hover{
  transition: ease-in-out 0.2s;
  box-shadow: initial 15px 15px 15px;
  text-decoration: none;
}

.paginaWarper {
  max-width: 50rem;
}

.iframeWarp {
  width: 50%;
  background-color: red;
}
.perfilNavLink {
  padding-left: 1rem;
}

.perfilNavLink:hover {
  background: #CED4DA;
}
.PerfilDashboardTopbar {
  width: max-content;
  outline: 0.2rem solid rgb(151, 151, 151);
  outline-offset: 0.2rem;
}

.PerfilDashboardTopbarDark {
  width: max-content;
  outline: 0.2rem solid rgb(151, 151, 151);
  outline-offset: 0.2rem;
}



.PerfilDashboardTopbar:hover {
  outline: 0.2rem solid rgb(85, 85, 85);
  outline-offset: 0.2rem;

  .ImgPerfilDashboardTopbar {
    outline: 0.2rem solid rgb(85, 85, 85);
      outline-offset: 0.2rem;
    }
    }
    
    ;
    
    
    /*---------------------------------------------------------------------------------------------------------*/
    
    .PerfilDashboardTopbarDark:hover {
      outline: 0.2rem solid rgb(255, 255, 255);
      outline-offset: 0.2rem;
    
      .ImgPerfilDashboardTopbarDark {
        outline: 0.2rem solid rgb(255, 255, 255);
        outline-offset: 0.2rem;
      }
    }

.ImgPerfilDashboardTopbar {
  width: 2.5rem;
  height: 2.5rem;
  object-fit: cover;
  outline: 0.2rem solid rgb(151, 151, 151);
    outline-offset: 0.2rem;
}

.ImgPerfilDashboardTopbarDark {
  width: 2.5rem;
  height: 2.5rem;
  object-fit: cover;
  outline: 0.2rem solid rgb(151, 151, 151);
    outline-offset: 0.2rem;
}

.ImgPerfilDashboardTopbar:active {
  outline: 0.2rem solid rgba(255, 255, 255, 0.315);
  outline-offset: 0.2rem;
}

.ImgPerfilDashboardTopbarDark:active {
  outline: 0.2rem solid rgba(0, 0, 0, 0.315);
  outline-offset: 0.2rem;
}

.ImgPerfilDashboardTopbarPerfil {
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  outline: 0.2rem solid #CED4DA;
  /* outline-offset: 0.2rem; */
}
/*Perfil End*/
.paginaImagePerfil {
  width: 6rem;
    height: 6rem;
    object-fit: cover;
  }
  
.paginaMolduraPerfil {
  position: absolute;
  width: 7.9rem;
  /* Largura igual à imagem perfil */
  height: 7.9rem;
  /* Ou ajuste conforme necessário */
}
.paginaUsernamePerfil,
.paginaNivelWarper {
  cursor: default;
}
.perfilImgHomePage{
  width: 2rem;
  border-radius: 50%;
}

.paginaFooter{
  position: fixed;
  bottom: 0;
  width:100%;
  text-decoration: none;
  background: rgb(0,0,0);
  background: -moz-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
}


.navLinksTop{
  align-items: center;
}

.navLinksTopBar{
  justify-content:flex-end;
}
.iconShowEditUsername {
  cursor: pointer;
}



.fundo1HomePage {
  padding-top: 5rem;
  padding-bottom: 2rem;
  width: 100%;
  height: 100%;
  background-color: green;
}

.fundo2HomePage{
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: 100%;
  height: 100%;
  background-color: indianred;
}

.ImgLogoHomeTopbar {
  width: 8rem;
  margin: 1rem;
  /* filter: invert(); */
}
.HomePrimeiraDiv {
  max-width: 100vw !important;
  color: antiquewhite;
  margin-left: 0 !important; 
  margin-right: 0 !important;
  margin-top: 0;
  padding-top: 6rem;
  font-family: "Press Start 2P", system-ui;
  font-weight: lighter;
  font-style: normal;
}


.HomePrimeiraDivBannerDireita {
  margin: auto;
  margin-top: 3rem;
  text-align: center;
}

.homesegundadiv,
.hometerceiradiv,
.homequartadiv,
.homeqeadiv,
.precos {
  max-width: 100vw !important;
  margin-left: 0 !important; 
  margin-right: 0 !important;
  margin-top: 0;
  font-family: "Press Start 2P", system-ui;
  font-weight: lighter;
  font-style: normal
}
.FAQIcons {
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: 3rem;
}

.homeqeadiv {
  /* background-color: #b6eaff; */
}
.hometerceiradiv {
  padding: auto;
  display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .homequartadiv {
    padding: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .hometerceiradivrow {
    width: 60rem;
  }
  
  .hometerceiradivimg {
    margin: 1rem auto;
    min-height: min-content;
    border: #000 solid 0.1rem;
  }
.paginaFooterItem{
  margin-left: auto;
  margin-right: auto;
}

.bn632-hover {
  width: 15%;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  margin: 20px;
  height: 55px;
  text-align:center;
  border: none;
  background-size: 300% 100%;
  border-radius: 50px;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.bn632-hover:hover {
  background-position: 100% 0;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.bn632-hover:focus {
  outline: none;
}

.bn632-hover.bn22 {
  background-image: linear-gradient(
    to right,
    #0ba360,
    #3cba92,
    #30dd8a,
    #2bb673
  );
  box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
}

.perfilImgZoomRange{
  margin:auto;
}

.perfilImgZoomRangeLabel{
  width: 10rem;
}

.AvatarEditorCss{
  margin: auto;
  
}
.personalizarContainers {
  width: 90% !important;
  margin-bottom: 5%;
  box-shadow: #000 10px 10px 15px;
}
.profileContainers {
  margin-top: 2rem;
  margin-bottom: 5%;
  margin-right: 2rem;
  box-shadow: #00000079 0.3rem 0.3rem 0.5rem;
    overflow: visible;
}
.colecionaveisContainers {
  width: 100% !important;
  margin-bottom: 5%;
  background-color: white;
  box-shadow: #000 10px 10px 15px;
}
.editFundo {
  width: 100%;
  border: #000 solid 0.1rem
}

.accordion-collapse {
  width: 100%;

}

.AccBody {
  display: flex;
  justify-content: center;
  height: 18rem;
}

.previewFundo {
  transition: max-height 0.3s ease;
  display: flex;
  flex-direction: column;
  position: -webkit-sticky; /* para compatibilidade com navegadores antigos */
  position: sticky;
  top: 0;
  z-index: 10;  
}

.miniContainer{
  opacity: 0;
  height: 0px;
}

.expand-btn {
  opacity: 0;
  display: none;
  font-size: 0;
    cursor: default !important;
}

/*PopUp Conquista*/
.conquista {
  border: #595959 solid .3rem;
  padding: 0 !important;
}


/* custom-toast.css */
@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 1;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(100%);
    opacity: 1;
  }
}

.toast-slide-in {
  animation: slideIn 0.5s forwards;
}

.toast-slide-out {
  animation: slideOut 0.5s forwards;
}


.userGemas {
  border-color: #595959;
  border-width: 0.3rem;
  border-style: solid;

  cursor: default;
  }










@media only screen and (max-width: 768px) {
  /* Estilos específicos para dispositivos móveis */
.conquista {
    width: 100% !important;
  }
  .paginaFundo{
      background-position-x: 65%;
      background-size: cover;
      width: 100%;
    background-color: beige;
  }
  .paginaFundoWarp{
    height: 100dvh;
      width: 100dvw;
    display:block;
    background: #000;
  }

  .bn632-hover {
    width: 50%;
  }
.paginaFooter {
  height: 20vh;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-decoration: none;
  background: rgb(0, 0, 0);
  background: -moz-linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
}
.hometerceiradivrow {
  width: 100vw;
}
.personalizarContainers {
  width: 100% !important;
}

.previewFundo {
  
  opacity: 0;
  max-height: 0;
  overflow: hidden;
}

.profileFundo {
  top:auto;
  height: auto !important;
}
.profileContainers {
  margin-top: 0%;
  opacity: 0;
  height: 0;
}
.previewFundo.expanded {
  max-height: 100dvh;
  opacity: 1;
  /* Or any height you prefer when expanded */
}

.miniContainer{
  opacity: 1;
  height: auto;
}

.expand-btn {
  opacity: 1;
  display: block;
  margin-bottom: 1rem;
  height: inherit;
  width: inherit;
}
.logologinup {
  position: absolute;
  width: 40%;
}

.backFormupin {
  margin-top: 9rem;
}
.AccBody {
  height: 20rem;
  overflow-y: scroll;
}
}

