.form-control:focus {
    box-shadow: none;
    border-color: #BA68C8
}

.profile-button {
    background: rgb(99, 39, 120);
    box-shadow: none;
    border: none
}

.profile-button:hover {
    background: #682773
}

.profile-button:focus {
    background: #682773;
    box-shadow: none
}

.profile-button:active {
    background: #682773;
    box-shadow: none
}

.back:hover {
    color: #682773;
    cursor: pointer
}

.labels {
    font-size: 11px
}

.add-experience:hover {
    background: #BA68C8;
    color: #fff;
    cursor: pointer;
    border: solid 1px #BA68C8
}


.editIconPerfil,
.deleteIconPerfil {
  color: rgb(129, 129, 129);
  font-size: 20px;
  cursor: pointer;
  z-index: 3;
}

.editIconPerfil:hover,
.deleteIconPerfil:hover {
  color: white;
}
.modaldeletelight {
    .modal-content {
        background-color: white;
        border-radius: .6rem;
    }
}

.modaldeletedark {
    .modal-content {
        background-color: #212529;
        color: azure;
        border-radius: .6rem;
    }
}