    /* Barra de rolagem para navegadores WebKit */
    ::-webkit-scrollbar {
        width: 0px;
        /* Largura da barra de rolagem vertical */
        /* Altura da barra de rolagem horizontal */
    }

    ::-webkit-scrollbar-track {
        background: #f1f1f1;
        /* Cor do fundo da trilha da barra de rolagem */
        border-radius: 10px;
        /* Raio da borda da trilha da barra de rolagem */
    }

    ::-webkit-scrollbar-thumb {
        background: #888;
        /* Cor do polegar da barra de rolagem */
        border-radius: 10px;
        /* Raio da borda do polegar da barra de rolagem */
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #555;
        /* Cor do polegar da barra de rolagem ao passar o mouse */
    }

    /* Barra de rolagem para Firefox */
    * {
        scrollbar-width: none;
        /* Largura da barra de rolagem (pode ser auto, thin ou none) */
        scrollbar-color: #f1f1f1 #f1f1f100;
        /* Cor do polegar e da trilha da barra de rolagem */
    }

    .img-thumbnail {
        background-color: #ffffff00;
    }

    .img-thumbnail:hover {
        background-color: gray;
    }